import { createAction, props } from '@ngrx/store';

import { ApiPartnerManager } from '../../models';

export const loadRequest = createAction(
  '[ApiPartnerManager] Load Request',
  props<{
    apiPartnerId: number;
  }>(),
);

export const loadSuccess = createAction(
  '[ApiPartnerManager] Load Success',
  props<{ apiPartnerManager: ApiPartnerManager }>(),
);

export const loadFailure = createAction(
  '[ApiPartnerManager] Load Failure',
  props<{ error: any }>(),
);

export const attachRequest = createAction(
  '[ApiPartnerManager] Attach Request',
  props<{
    apiPartnerId: number;
    userId?: number;
  }>(),
);

export const attachSuccess = createAction(
  '[ApiPartnerManager] Attach Success',
  props<{ apiPartnerManager: ApiPartnerManager }>(),
);

export const attachFailure = createAction(
  '[ApiPartnerManager] Attach Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[ApiPartnerManager] Reset State');
