import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiPartnerManagerStoreEffects } from './effects';
import { apiPartnerManagerReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('apiPartnerManager', apiPartnerManagerReducer),
    EffectsModule.forFeature([ApiPartnerManagerStoreEffects]),
  ],
})
export class ApiPartnerManagerStoreModule {}
