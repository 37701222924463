import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { ApiPartnerManagerService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ApiPartnerManagerStoreEffects {
  constructor(
    private dataService: ApiPartnerManagerService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifcations: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ apiPartnerId }) =>
        this.dataService.load(apiPartnerId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({ apiPartnerManager: response.data[0] }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  attach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.attachRequest),
      switchMap(({ apiPartnerId, userId }) =>
        this.dataService.attach(apiPartnerId, userId).pipe(
          map((response: IResponseSuccess) => {
            this.notifcations.updateSuccess('manager');
            return fromActions.attachSuccess({
              apiPartnerManager: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.attachFailure(error));
          }),
        ),
      ),
    ),
  );
}
